import request from '@/api/request';
export const yuyueset = (data) => request({ method: 'post', data, url: 'https://www.haikoutour.cn/Api/yuyue' })
export const setjzorder = (data) => request({ method: 'post', data, url: 'https://www.haikoutour.cn/Api/JZpay' });
export const setwjorder = (data) => request({ method: 'post', data, url: 'https://www.haikoutour.cn/Api/wjpay' })

export const getwlbig = () => request({ url: 'https://www.haikoutour.cn/Api/getwlbig', method: 'get' })

export const getjytitle = () => request({ url: 'https://www.haikoutour.cn/Api/getjytitle', method: 'get' })
export const getjygg = () => request({ url: 'https://www.haikoutour.cn/Api/getjygg', method: 'get' })
export const getjylbt = () => request({ url: 'https://www.haikoutour.cn/Api/getjylbt', method: 'get' })
export const getjylbttext = () => request({ url: 'https://www.haikoutour.cn/Api/getjylbttext', method: 'get' })
export const getjyfll = () => request({ url: 'https://www.haikoutour.cn/Api/getjyfll', method: 'get' })
export const getjyjyyw = () => request({ url: 'https://www.haikoutour.cn/Api/getjyjyyw', method: 'get' })
export const getjyjyfb = () => request({ url: 'https://www.haikoutour.cn/Api/getjyjyfb', method: 'get' })
export const getjytjjy = () => request({ url: 'https://www.haikoutour.cn/Api/getjytjjy', method: 'get' })
export const getjyzcfg = () => request({ url: 'https://www.haikoutour.cn/Api/getjyzcfg', method: 'get' })
export const getjyjysj = () => request({ url: 'https://www.haikoutour.cn/Api/getjyjysj', method: 'get' })
export const getjyzkgd = () => request({ url: 'https://www.haikoutour.cn/Api/getjyzkgd', method: 'get' })
export const getjyyxdt = () => request({ url: 'https://www.haikoutour.cn/Api/getjyyxdt', method: 'get' })
export const getjyzthd = () => request({ url: 'https://www.haikoutour.cn/Api/getjyzthd', method: 'get' })
export const getjyjyws = () => request({ url: 'https://www.haikoutour.cn/Api/getjyjyws', method: 'get' })