import Vue from "vue";
import VueRouter from "vue-router";

import Shoye from '@/pages/shoye'
Vue.use(VueRouter);
const router = new VueRouter({
        mode: 'history',
        routes: [{
                path: '*',
                redirect: '/'
            }, {
                path: '/',
                name: 'Shoye',
                component: Shoye,

            },

        ],
        // 路由跳转后，位置为顶部
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return
            } else {
                return { x: 0, y: 0 }
            }

        }
    })
    // router.beforeEach((to, from, next) => {
    //     if (to.path === '/search') {
    //         to.meta.keepAlive = true;
    //     } else if (to.path === '/details' && from.path === '/search') {
    //         from.meta.keepAlive = true;
    //     } else {
    //         to.meta.keepAlive = false;
    //     }
    //     next()
    // })
export default router;