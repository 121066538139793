<template>
  <div
    style="
      height: 100%;
      width: 100%;

      margin: 0 auto;
      margin: auto;
      font-family: '微软雅黑';
      position: relative;
    "
  >
    <!-- 头部 -->
    <div style="width: 100%; height: 46px; background-color: #ececec">
      <div
        style="
          width: 100%;
          max-width: 1200px;
          min-width: 800px;
          margin: auto;
          display: flex;
          justify-content: space-between;
        "
      >
        <div
          style="
            width: 154px;
            height: 100%;
            color: #666666;
            font-size: 14px;
            line-height: 46px;
            margin-left: 20px;
          "
        >
          海南教育网官方
        </div>
        <div
          style="
            width: 190px;
            height: 46px;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div class="top1 topone" style="margin-right: 20px">
            <!-- <div style="width: 100%;height: 100%;line-height: 46px;">品牌矩阵<span class="icon1 el-icon-arrow-down"></span></div> -->

            <ul class="top1box">
              <li>
                <a href="https://www.eol.cn/e_gx/zt/zsgk.html">掌上高考</a>
              </li>
              <li>
                <a
                  href="https://kaoyan.eol.cn/e_ky/zt/download/appdownload.html"
                  >掌上考研</a
                >
              </li>
              <li>
                <a href="https://www.acabridge.cn/">学 术 桥</a>
              </li>
              <li>
                <a href="https://shengxue.eol.cn/">学 业桥</a>
              </li>
              <li>
                <a href="https://www.jiuyeqiao.cn/">就 业 桥</a>
              </li>
              <li>
                <a href="https://www.zhijiao.cn/hain-hk">职 教 网</a>
              </li>
              <li>
                <a href="about:blank">阳光继教</a>
              </li>
            </ul>
          </div>
          <div class="top1 toptwo">
            <ul class="top2box">
              <li>
                <a
                  href="https://article.xuexi.cn/articles/index.html?art_id=15277482638330755802&t=1606204122730&showmenu=false&study_style_id=feeds_default&source=share&share_to=copylink&item_id=15277482638330755802&ref_read_id=8d0b8f7a-425f-41e8-bfe2-61ca16e09c48_1672130807408"
                  >学习强国</a
                >
              </li>
              <li>
                <a
                  href="https://passport.weibo.com/visitor/visitor?entry=miniblog&a=enter&url=https%3A%2F%2Fweibo.com%2Fu%2F2613324761&domain=weibo.com&ua=Mozilla%2F5.0%20%28Windows%20NT%2010.0%3B%20Win64%3B%20x64%29%20AppleWebKit%2F537.36%20%28KHTML%2C%20like%20Gecko%29%20Chrome%2F114.0.0.0%20Safari%2F537.36&_rand=1687684985969&sudaref="
                  >官方微博</a
                >
              </li>
              <li>
                <a href="https://wap.peopleapp.com/rmh/4482">人民号</a>
              </li>
              <li>
                <a
                  href="https://www.toutiao.com/c/user/token/MS4wLjABAAAAOEB3yyoIMZXjTK2kgFM8J89Aeqd2flM1bcsEPoWYBhs/?wid=1687685015302"
                  >头条号</a
                >
              </li>
              <li>
                <a
                  href="https://mp.sohu.com/profile?xpt=cGJsZ18xNTExNzlAc29odS5jb20=&scm=1019.20005.0.0.0&spm=smpc.csrpage.suggest-list.1.1668065966197XBTXbfg"
                  >搜狐号</a
                >
              </li>
              <li>
                <a href="https://www.163.com/dy/media/T1449543923423.html"
                  >网易号</a
                >
              </li>
              <li>
                <a href="https://author.baidu.com/home/1572620362636189"
                  >百家号</a
                >
              </li>
              <li>
                <a
                  href="https://www.douyin.com/user/MS4wLjABAAAAQgEveyT2J57q6pfUENa3BN3yyFfLPOP9Y3JPUzhz7V8"
                  >抖音号</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        width: 1200px;
        height: 98px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div
        style="
          width: 489px;
          height: 48px;
          margin-left: 20px;
          margin-bottom: 25px;
        "
      >
        <img
          src="https://s1.ax1x.com/2023/06/30/pC0Jk8J.png"
          alt=""
          style="width: 241px; height: 78px"
        />
      </div>
      <div
        style="
          width: 270px;
          height: 48px;
          display: flex;
          align-items: center;
          margin-right: 20px;
        "
      >
        <div
          style="
            width: 300px;
            height: 28px;
            background-color: #ffffff;
            display: flex;
            padding-left: 10px;
            box-sizing: border-box;
            align-items: center;
            border: 1px solid #ccc;
            border-radius: 3px;
          "
        >
          <input
            type="text"
            v-model="inputvalue"
            style="
              width: 100%;
              box-shadow: none;
              outline: none;
              height: 23px;
              border: none;
            "
          /><span class="el-icon-search" @click="inputHanlder"></span>
        </div>
      </div>
    </div>
    <!-- 导航条 -->
    <div style="width: 100%; height: 60px; background-color: #004eb3">
      <div
        style="
          width: 1200px;
          height: 100%;
          margin: auto;
          box-sizing: border-box;
        "
      >
        <ul
          class="navul"
          style="display: flex; justify-content: space-between; padding: 0 30px"
        >
          <a href=""
            ><li>
              <span>首页</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href="http://edu.hainan.gov.cn/edu/ywdt/news.shtml"
            ><li>
              <span>要闻动态</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href="http://edu.hainan.gov.cn/edu/zwdt/tylist.shtml"
            ><li>
              <span> 教育动态</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href="http://edu.hainan.gov.cn/edu/0200/newxxgk_list.shtml"
            ><li>
              <span>信息公开</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href="http://edu.hainan.gov.cn/edu/jdhy/jdhy.shtml"
            ><li>
              <span>解读回应</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a
            href="https://wssp.hainan.gov.cn/hnwt/department?regionId=HZ2881f4424539dd0142453c856b0025&orgId=HZ28810342933aa90142ad0c012f3546&serviceSubType=org"
            ><li>
              <span> 政务服务</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href="http://edu.hainan.gov.cn/edu/0500/tylist.shtml"
            ><li>
              <span>教育政策</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href="http://edu.hainan.gov.cn/edu/hdjl/hdjl.shtml"
            ><li>
              <span>交流互动</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href="http://edu.hainan.gov.cn/edu/0900/tiao1tt.shtml"
            ><li>
              <span>数据开放</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a
            href="https://wssp.hainan.gov.cn/hnwt/department?regionId=HZ2881f4424539dd0142453c856b0025&orgId=HZ28810342933aa90142ad0c012f3546&serviceSubType=org
"
            ><li>
              <span>办事服务</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
        </ul>
      </div>
    </div>
    <div style="width: 100%; height: 40px; background-color: #f3f5f6">
      <div
        style="
          width: 1200px;
          height: 100%;
          margin: auto;
          box-sizing: border-box;
        "
      >
        <ul
          class="navultwo"
          style="
            display: flex;
            justify-content: space-between;
            padding: 0 20px;
            padding-left: 15px;
          "
        >
          <a href=""
            ><li>
              <span>中小学</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>高考</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>考研</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>中职</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>就业</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>规划</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>教师</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>人才服务</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>数字教育</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>数字人才</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>高校教育</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>家庭教育</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
          <a href=""
            ><li>
              <span>思政教育</span>
              <div
                style="
                  width: 80px;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50px;
                "
                class="nav_icon"
              ></div></li
          ></a>
        </ul>
      </div>
    </div>
    <h3 style="width: 100%; text-align: center; margin-top: 50px">
      <a :href="title.aone" class="title">{{ title.titleone }}</a>
      <a :href="title.atwo" class="title" style="margin-left: 20px">{{
        title.titletwo
      }}</a>
    </h3>
    <ul style="width: 1200px; margin: auto; margin-top: 40px" class="zxnl">
      <a :href="title.a1">
        <li>
          {{ title.text1 }}
        </li>
      </a>
      <a :href="title.a2">
        <li>
          {{ title.text2 }}
        </li>
      </a>
      <a :href="title.a3">
        <li>
          {{ title.text3 }}
        </li>
      </a>
    </ul>
    <!-- 轮播图 -->
    <div
      style="
        width: 1200px;
        height: 322px;
        margin: auto;
        margin-top: 40px;
        display: flex;
      "
    >
      <div class="leftbox1">
        <ul class="leftbox1ul">
          <a :href="gg.aone"
            ><li style="font-size: 21px; font-weight: bold">
              {{ gg.titleone }}
            </li></a
          >

          <a :href="gg.aone1"
            ><li class="leftbox1li">. {{ gg.textone1 }}</li></a
          >
          <a :href="gg.aone2"
            ><li class="leftbox1li">. {{ gg.textone2 }}</li></a
          >
          <a :href="gg.aone3"
            ><li class="leftbox1li">. {{ gg.textone3 }}</li></a
          >
        </ul>
        <ul class="leftbox1ul" style="margin-top: 30px">
          <a :href="gg.atwo"
            ><li style="font-size: 21px; font-weight: bold">
              {{ gg.titletwo }}
            </li></a
          >

          <a :href="gg.atwo1"
            ><li class="leftbox1li">. {{ gg.texttwo1 }}</li></a
          >
          <a :href="gg.atwo2"
            ><li class="leftbox1li">. {{ gg.texttwo2 }}</li></a
          >
          <a :href="gg.atwo3"
            ><li class="leftbox1li">. {{ gg.texttwo3 }}</li></a
          >
        </ul>
      </div>
      <div class="rightbox1">
        <swiper></swiper>
        <div style="width: 100%; height: 48px; display: flex; margin-top: 10px">
          <div
            style="
              width: 206px;
              height: 100%;
              padding: 0 20px;
              border-right: 1px solid #ccc;
            "
          >
            <a :href="lbttext.a1" class="lbtbox">{{ lbttext.text1 }}</a>
          </div>
          <div
            style="
              width: 206px;
              height: 100%;
              padding: 0 20px;
              border-right: 1px solid #ccc;
            "
          >
            <a :href="lbttext.a2" class="lbtbox">{{ lbttext.text2 }}</a>
          </div>
          <div style="width: 206px; height: 100%; padding: 0 20px">
            <a :href="lbttext.a3" class="lbtbox">{{ lbttext.text3 }}</a>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        height: 162px;
        background-color: rgb(243, 245, 246);
        margin-top: 10px;
        padding: 30px 0;
      "
    >
      <div style="width: 1200px; height: 162px; margin: auto">
        <ul style="width: 1200px; height: 100px" class="ulimg">
          <a :href="fll.aone"
            ><li style=""><img :src="fll.imgone" style="height: 100px" /></li
          ></a>
          <a :href="fll.atwo"
            ><li style=""><img :src="fll.imgtwo" style="height: 100px" /></li
          ></a>
          <a :href="fll.athree"
            ><li style=""><img :src="fll.imgthree" style="height: 100px" /></li
          ></a>
          <a :href="fll.af"
            ><li style=""><img :src="fll.imgf" style="height: 100px" /></li
          ></a>
        </ul>
        <div
          style="width: 1200px; height: 44px; display: flex; margin-top: 15px"
          class="imgbottom"
        >
          <div style="width: 285px; height: 44px; position: relative">
            <a :href="fll.aone1" style="position: relative"
              ><p class="p1">{{ fll.textone1 }}</p></a
            >
            <a :href="fll.aone2" style="position: relative"
              ><p class="p1">{{ fll.textone2 }}</p></a
            >
          </div>
          <div
            style="
              width: 285px;
              height: 44px;
              position: relative;
              margin-left: 20px;
            "
          >
            <a :href="fll.atwo1" style="position: relative"
              ><p class="p1">{{ fll.texttwo1 }}</p></a
            >
            <a :href="fll.aotwo2" style="position: relative"
              ><p class="p1">{{ fll.texttwo2 }}</p></a
            >
          </div>
          <div
            style="
              width: 285px;
              height: 44px;
              position: relative;
              margin-left: 20px;
            "
          >
            <a :href="fll.athree1" style="position: relative"
              ><p class="p1">{{ fll.textthree1 }}</p></a
            >
            <a :href="fll.athree2" style="position: relative"
              ><p class="p1">{{ fll.textthree2 }}</p></a
            >
          </div>
          <div
            style="
              width: 285px;
              height: 44px;
              position: relative;
              margin-left: 20px;
            "
          >
            <a :href="fll.af1" style="position: relative"
              ><p class="p1">{{ fll.textf1 }}</p></a
            >
            <a :href="fll.af2" style="position: relative"
              ><p class="p1">{{ fll.textf2 }}</p></a
            >
          </div>
        </div>
      </div>
    </div>

    <div style="width: 1200px; height: 745px; margin: auto; display: flex">
      <div style="width: 820px; height: 100%; overflow: hidden">
        <div
          style="
            width: 820px;
            height: 24px;
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
          "
        >
          <div
            style="font-size: 18px; color: rgb(0, 78, 179); margin-left: 10px"
          >
            <span>教育要闻</span>
            <span class="s1">高档教育</span>
            <span class="s1">职业教育</span>
            <span class="s1">国际教育</span>
            <span class="s1">继续教育</span>
            <span class="s1">基础教育</span>
          </div>

          <div
            style="
              background-image: url('https://www.eol.cn/e_images/index/2022/index/columnMore.png');
              width: 21px;
              height: 24px;
              margin-right: 10px;
            "
          ></div>
        </div>
        <div
          style="
            width: 100%;
            height: 1px;
            background-color: #ccc;
            margin-top: 10px;
          "
        >
          <div
            style="width: 90px; height: 2px; background-color: #004eb3"
          ></div>
        </div>

        <div
          style="
            width: 100%;
            height: 65px;
            border-bottom: 1px solid #cccc;
            margin-top: 20px;
            padding-left: 10px;
            box-sizing: border-box;
          "
        >
          <p class="p2"></p>
          <a :href="yw.a1"
            ><p class="p2">
              {{ yw.text1 }}
            </p></a
          >
          <div
            style="
              font-size: 12px;
              color: #004eb3;
              margin-top: 10px;
              position: relative;
            "
          >
            {{ yw.ly1 }}
            <div
              style="
                width: 120px;
                height: 40px;
                display: flex;
                position: absolute;
                right: 0;
                top: -15px;
              "
            ></div>
          </div>
        </div>

        <div
          style="
            width: 100%;
            height: 65px;
            border-bottom: 1px solid #cccc;
            margin-top: 20px;
            padding-left: 10px;
            box-sizing: border-box;
          "
        >
          <p class="p2"></p>
          <a :href="yw.a2"
            ><p class="p2">
              {{ yw.text2 }}
            </p></a
          >
          <div
            style="
              font-size: 12px;
              color: #004eb3;
              margin-top: 10px;
              position: relative;
            "
          >
            {{ yw.ly2 }}
            <div
              style="
                width: 120px;
                height: 40px;
                display: flex;
                position: absolute;
                right: 0;
                top: -15px;
              "
            ></div>
          </div>
        </div>

        <div
          style="
            width: 100%;
            height: 65px;
            border-bottom: 1px solid #cccc;
            margin-top: 20px;
            padding-left: 10px;
            box-sizing: border-box;
          "
        >
          <p class="p2"></p>
          <a :href="yw.a3"
            ><p class="p2">
              {{ yw.text3 }}
            </p></a
          >
          <div
            style="
              font-size: 12px;
              color: #004eb3;
              margin-top: 10px;
              position: relative;
            "
          >
            {{ yw.ly3 }}
            <div
              style="
                width: 120px;
                height: 40px;
                display: flex;
                position: absolute;
                right: 0;
                top: -15px;
              "
            ></div>
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 65px;
            border-bottom: 1px solid #cccc;
            margin-top: 20px;
            padding-left: 10px;
            box-sizing: border-box;
          "
        >
          <p class="p2"></p>
          <a :href="yw.a4"
            ><p class="p2">
              {{ yw.text4 }}
            </p></a
          >
          <div
            style="
              font-size: 12px;
              color: #004eb3;
              margin-top: 10px;
              position: relative;
            "
          >
            {{ yw.ly4 }}
            <div
              style="
                width: 120px;
                height: 40px;
                display: flex;
                position: absolute;
                right: 0;
                top: -15px;
              "
            ></div>
          </div>
        </div>
        <div
          style="
            width: 100%;
            height: 65px;
            margin-top: 20px;
            padding-left: 10px;
            box-sizing: border-box;
          "
        >
          <p class="p2"></p>
          <a :href="yw.a5"
            ><p class="p2">
              {{ yw.text5 }}
            </p></a
          >
          <div
            style="
              font-size: 12px;
              color: #004eb3;
              margin-top: 10px;
              position: relative;
            "
          >
            {{ yw.ly5 }}
            <div
              style="
                width: 120px;
                height: 40px;
                display: flex;
                position: absolute;
                right: 0;
                top: -15px;
              "
            ></div>
          </div>
        </div>

        <a href="https://chuzhong.eol.cn/news/ "
          ><div
            style="
              width: 100%;
              height: 50px;
              background-color: #f6f6f6;
              text-align: center;
              line-height: 50px;
              margin-top: 30px;
            "
          >
            查看更多资讯 >>
          </div>
        </a>
        <a href="https://news.eol.cn/20da/"
          ><div style="width: 100%; height: 90px; margin-top: 30px">
            <img
              src="https://www.eol.cn/e_images/index/2022/index/b1b.jpg"
              alt=""
              style="width: 100%"
            />
          </div>
        </a>
      </div>
      <div style="width: 330px; margin-left: 50px">
        <div style="width: 330px; height: 353px">
          <div
            style="
              width: 330px;
              height: 24px;
              display: flex;
              justify-content: space-between;
              margin-top: 30px;
            "
          >
            <div
              style="font-size: 18px; color: rgb(0, 78, 179); margin-left: 10px"
            >
              教育信息
            </div>
          </div>
          <div
            style="
              width: 100%;
              height: 1px;
              background-color: #ccc;
              margin-top: 10px;
            "
          >
            <div
              style="width: 90px; height: 2px; background-color: #004eb3"
            ></div>
          </div>
          <div style="width: 330px; height: 100px; margin-top: 15px">
            <a :href="jyfb.a">
              <img :src="jyfb.url" alt="" style="width: 330px; height: 100px" />
            </a>
          </div>
          <ul class="righbox2ul">
            <a :href="jyfb.a1"
              ><li>. {{ jyfb.text1 }}</li></a
            >
            <a :href="jyfb.a2"
              ><li>. {{ jyfb.text2 }}</li></a
            >
            <a :href="jyfb.a3"
              ><li>. {{ jyfb.text3 }}</li></a
            >
            <a :href="jyfb.a4"
              ><li>. {{ jyfb.text4 }}</li></a
            >
            <a :href="jyfb.a5"
              ><li>. {{ jyfb.text5 }}</li></a
            >
          </ul>
        </div>
        <div style="width: 330px; height: 363px">
          <div
            style="
              width: 330px;
              height: 24px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div
              style="font-size: 18px; color: rgb(0, 78, 179); margin-left: 10px"
            >
              图解教育
            </div>
          </div>
          <div
            style="
              width: 100%;
              height: 1px;
              background-color: #ccc;
              margin-top: 10px;
            "
          >
            <div
              style="width: 90px; height: 2px; background-color: #004eb3"
            ></div>
          </div>
          <div style="width: 330px; height: 100px; margin-top: 15px">
            <a :href="tjjy.a">
              <img :src="tjjy.url" alt="" style="width: 330px; height: 100px" />
            </a>
          </div>
          <ul class="righbox2ul">
            <a :href="tjjy.a1"
              ><li>. {{ tjjy.text1 }}</li></a
            >
            <a :href="tjjy.a2"
              ><li>. {{ tjjy.text2 }}</li></a
            >
            <a :href="tjjy.a3"
              ><li>. {{ tjjy.text3 }}</li></a
            >
            <a :href="tjjy.a4"
              ><li>. {{ tjjy.text4 }}</li></a
            >
            <a :href="tjjy.a5"
              ><li>. {{ tjjy.text5 }}</li></a
            >
          </ul>
        </div>
      </div>
    </div>

    <div style="width: 1200px; height: 745px; margin: auto; display: flex">
      <div style="width: 820px; height: 100%">
        <div style="width: 100%; height: 283.5px; display: flex">
          <div style="width: 370px; height: 100%">
            <div
              style="
                width: 100%;
                height: 43px;
                line-height: 43px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/special_1.png"
                  alt=""
                  style="width: 46px; height: 43px"
                />
                <a href="https://www.eol.cn/zhengce/"
                  ><p class="p3">最新政策</p></a
                >
              </div>
              <div
                style="
                  margin-right: 10px;
                  width: 21px;
                  height: 26px;
                  background-image: url('	https://www.eol.cn/e_images/index/2022/index/columnMore.png');
                "
              ></div>
            </div>
            <div
              style="
                width: 100%;
                height: 1px;
                background-color: #ccc;
                margin-top: 10px;
                margin-bottom: 10px;
              "
            ></div>
            <div style="display: flex; align-items: center">
              <img :src="zcfg.url" alt="" style="width: 125px; height: 64px" />
              <a :href="zcfg.a1"
                ><span class="span1">
                  {{ zcfg.text1 }}
                </span></a
              >
            </div>
            <ul class="leftbox2ul">
              <a :href="zcfg.a2">
                <li>. {{ zcfg.text2 }}</li>
              </a>
              <a :href="zcfg.a3">
                <li>. {{ zcfg.text3 }}</li>
              </a>
              <a :href="zcfg.a4">
                <li>. {{ zcfg.text4 }}</li>
              </a>
              <a :href="zcfg.a5">
                <li>. {{ zcfg.text5 }}</li>
              </a>
            </ul>
          </div>
          <div style="width: 370px; height: 100%; margin-left: 80px">
            <div
              style="
                width: 100%;
                height: 43px;
                line-height: 43px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/special_2.png"
                  alt=""
                  style="width: 46px; height: 43px"
                />
                <a href="https://www.eol.cn/shuju/"
                  ><p class="p3">数字教育</p></a
                >
              </div>
              <div
                style="
                  margin-right: 10px;
                  width: 21px;
                  height: 26px;
                  background-image: url('	https://www.eol.cn/e_images/index/2022/index/columnMore.png');
                "
              ></div>
            </div>
            <div
              style="
                width: 100%;
                height: 1px;
                background-color: #ccc;
                margin-top: 10px;
                margin-bottom: 10px;
              "
            ></div>
            <div style="display: flex; align-items: center">
              <img :src="jysj.url" alt="" style="width: 125px; height: 64px" />
              <a :href="jysj.a1"
                ><span class="span1">
                  {{ jysj.text1 }}
                </span></a
              >
            </div>
            <ul class="leftbox2ul">
              <a :href="jysj.a2">
                <li>. {{ jysj.text2 }}</li>
              </a>
              <a :href="jysj.a3">
                <li>. {{ jysj.text3 }}</li>
              </a>
              <a :href="jysj.a4">
                <li>. {{ jysj.text4 }}</li>
              </a>
              <a :href="jysj.a5">
                <li>. {{ jysj.text5 }}</li>
              </a>
            </ul>
          </div>
        </div>

        <div
          style="width: 100%; height: 283.5px; display: flex; margin-top: 30px"
        >
          <div style="width: 370px; height: 100%">
            <div
              style="
                width: 100%;
                height: 43px;
                line-height: 43px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex">
                <img
                  src="	https://www.eol.cn/e_images/index/2022/index/special_3.png"
                  alt=""
                  style="width: 46px; height: 43px"
                />
                <a href="https://news.eol.cn/xueshu/">
                  <p class="p3">教育专栏</p></a
                >
              </div>
              <div
                style="
                  margin-right: 10px;
                  width: 21px;
                  height: 26px;
                  background-image: url('	https://www.eol.cn/e_images/index/2022/index/columnMore.png');
                "
              ></div>
            </div>
            <div
              style="
                width: 100%;
                height: 1px;
                background-color: #ccc;
                margin-top: 10px;
                margin-bottom: 10px;
              "
            ></div>
            <div style="display: flex; align-items: center">
              <img :src="zkgd.url" alt="" style="width: 125px; height: 64px" />
              <a :href="zkgd.a1"
                ><span class="span1">
                  {{ zkgd.text1 }}
                </span></a
              >
            </div>
            <ul class="leftbox2ul">
              <a :href="zkgd.a2">
                <li>. {{ zkgd.text2 }}</li>
              </a>
              <a :href="zkgd.a3">
                <li>. {{ zkgd.text3 }}</li>
              </a>
              <a :href="zkgd.a4">
                <li>. {{ zkgd.text4 }}</li>
              </a>
              <a :href="zkgd.a5">
                <li>. {{ zkgd.text5 }}</li>
              </a>
            </ul>
          </div>
          <div style="width: 370px; height: 100%; margin-left: 80px">
            <div
              style="
                width: 100%;
                height: 43px;
                line-height: 43px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="display: flex">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/special_4.png"
                  alt="	https://www.eol.cn/e_images/index/2022/index/special_4.png"
                  style="width: 46px; height: 43px"
                />
                <a href="https://news.eol.cn/dongtai/"
                  ><p class="p3">院校动态</p></a
                >
              </div>
              <div
                style="
                  margin-right: 10px;
                  width: 21px;
                  height: 26px;
                  background-image: url('https://www.eol.cn/e_images/index/2022/index/columnMore.png');
                "
              ></div>
            </div>
            <div
              style="
                width: 100%;
                height: 1px;
                background-color: #ccc;
                margin-top: 10px;
                margin-bottom: 10px;
              "
            ></div>
            <div style="display: flex; align-items: center">
              <img :src="yxdt.url" alt="" style="width: 125px; height: 64px" />
              <a :href="yxdt.a1"
                ><span class="span1">
                  {{ yxdt.text1 }}
                </span></a
              >
            </div>
            <ul class="leftbox2ul">
              <a :href="yxdt.a2">
                <li>. {{ yxdt.text2 }}</li>
              </a>
              <a :href="yxdt.a3">
                <li>. {{ yxdt.text3 }}</li>
              </a>
              <a :href="yxdt.a4">
                <li>. {{ yxdt.text4 }}</li>
              </a>
              <a :href="yxdt.a5">
                <li>. {{ yxdt.text5 }}</li>
              </a>
            </ul>
          </div>
        </div>
        <a href="https://news.eol.cn/dongtai/gxrs/"
          ><div style="width: 820px; height: 90px; margin-top: 10px">
            <img
              src="https://www.eol.cn/e_images/index/2022/index/rsc1.jpeg"
              alt=""
            />
          </div>
        </a>
      </div>
      <div style="width: 330px; margin-left: 50px">
        <div style="width: 330px; height: 353px">
          <div
            style="
              width: 330px;
              height: 24px;
              display: flex;
              justify-content: space-between;
              margin-top: 30px;
            "
          >
            <div
              style="font-size: 18px; color: rgb(0, 78, 179); margin-left: 10px"
            >
              信息公开
            </div>
          </div>
          <div
            style="
              width: 100%;
              height: 1px;
              background-color: #ccc;
              margin-top: 10px;
            "
          >
            <div
              style="width: 90px; height: 2px; background-color: #004eb3"
            ></div>
          </div>
          <div style="width: 330px; height: 100px; margin-top: 15px">
            <a :href="zthd.a">
              <img :src="zthd.url" alt="" style="width: 330px; height: 100px" />
            </a>
          </div>
          <ul class="righbox2ul">
            <a :href="zthd.a1"
              ><li>. {{ zthd.text1 }}</li></a
            >
            <a :href="zthd.a2"
              ><li>. {{ zthd.text2 }}</li></a
            >
            <a :href="zthd.a3"
              ><li>. {{ zthd.text3 }}</li></a
            >
            <a :href="zthd.a4"
              ><li>. {{ zthd.text4 }}</li></a
            >
            <a :href="zthd.a5"
              ><li>. {{ zthd.text5 }}</li></a
            >
          </ul>
        </div>
        <div style="width: 330px; height: 363px">
          <div
            style="
              width: 330px;
              height: 24px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div
              style="font-size: 18px; color: rgb(0, 78, 179); margin-left: 10px"
            >
              省府要闻
            </div>
          </div>
          <div
            style="
              width: 100%;
              height: 1px;
              background-color: #ccc;
              margin-top: 10px;
            "
          >
            <div
              style="width: 90px; height: 2px; background-color: #004eb3"
            ></div>
          </div>
          <div style="width: 330px; height: 100px; margin-top: 15px">
            <a :href="jyws.a"
              ><img :src="jyws.url" alt="" style="width: 330px; height: 100px"
            /></a>
          </div>
          <ul class="righbox2ul">
            <a :href="jyws.a1"
              ><li>. {{ jyws.text1 }}</li></a
            >
            <a :href="jyws.a2"
              ><li>. {{ jyws.text2 }}</li></a
            >
            <a :href="jyws.a3"
              ><li>. {{ jyws.text3 }}</li></a
            >
            <a :href="jyws.a4"
              ><li>. {{ jyws.text4 }}</li></a
            >
            <a :href="jyws.a5"
              ><li>. {{ jyws.text5 }}</li></a
            >
          </ul>
        </div>
      </div>
    </div>
    <div
      style="width: 100%; height: 145px; background-color: rgb(246, 246, 246)"
    >
      <div style="width: 1200px; height: 100%; margin: auto; display: flex">
        <div
          style="
            width: 70px;
            margin-left: 80px;
            padding-top: 10px;
            height: 145px;
            text-align: center;
            font-size: 30px;
            background: #004eb3
              url(https://www.eol.cn/e_images/index/2022/index/service-title-bg.png)
              no-repeat 2px 1px;
            font-weight: bold;
            color: #fff;
          "
        >
          <p
            style="
              display: inline-block;
              width: 38px;
              height: 58px;
              /* line-height: 30px; */
              writing-mode: vertical-lr;
            "
          >
            服
          </p>
          <p
            style="
              display: inline-block;
              width: 38px;
              height: 128px;
              /* line-height: 30px; */
              writing-mode: vertical-lr;
            "
          >
            务
          </p>
        </div>

        <ul class="iconul">
          <a href="https://wssp.hainan.gov.cn/hnwt/home">
            <li>
              <img
                src="https://www.eol.cn/e_images/index/2022/index/service-tab1.png"
                alt=""
                class="img1"
              />
              <img
                src="https://www.eol.cn/e_images/index/2022/index/hservice-tab1.png"
                alt=""
                class="img2"
              />

              <p class="p4">办事服务</p>
            </li>
          </a>
          <a href="http://edu.hainan.gov.cn/">
            <li>
              <img
                src="https://www.eol.cn/e_images/index/2022/index/service-tab2.png"
                alt=""
                class="img1"
              />
              <img
                src="	https://www.eol.cn/e_images/index/2022/index/hservice-tab2.png"
                alt=""
                class="img2"
              />

              <p class="p4">生涯规划</p>
            </li>

            <li>
              <img
                src="https://www.eol.cn/e_images/index/2022/index/service-tab3.png"
                alt=""
                class="img1"
              />
              <img
                src="	https://www.eol.cn/e_images/index/2022/index/hservice-tab3.png"
                alt=""
                class="img2"
              />

              <p class="p4">灵活就业</p>
            </li>
            <li>
              <img
                src="	https://www.eol.cn/e_images/index/2022/index/service-tab4.png"
                alt=""
                class="img1"
              />
              <img
                src="		https://www.eol.cn/e_images/index/2022/index/hservice-tab4.png"
                alt=""
                class="img2"
              />

              <p class="p4">人才服务</p>
            </li>
            <li>
              <img
                src="https://www.eol.cn/e_images/index/2022/index/service-tab5.png"
                alt=""
                class="img1"
              />
              <img
                src="https://www.eol.cn/e_images/index/2022/index/hservice-tab5.png"
                alt=""
                class="img2"
              />

              <p class="p4">规划发展</p>
            </li>
            <li>
              <img
                src="https://www.eol.cn/e_images/index/2022/index/service-tab6.png"
                alt=""
                class="img1"
              />
              <img
                src="https://www.eol.cn/e_images/index/2022/index/hservice-tab6.png"
                alt=""
                class="img2"
              />

              <p class="p4">考研服务</p>
            </li>
            <li>
              <img
                src="https://www.eol.cn/e_images/index/2022/index/service-tab7.png"
                alt=""
                class="img1"
              />
              <img
                src="https://www.eol.cn/e_images/index/2022/index/hservice-tab7.png"
                alt=""
                class="img2"
              />

              <p class="p4">继教服务</p>
            </li>

            <li>
              <img
                src="	https://www.eol.cn/e_images/index/2022/index/service-tab8.png"
                alt=""
                class="img1"
              />
              <img
                src="https://www.eol.cn/e_images/index/2022/index/hservice-tab8.png"
                alt=""
                class="img2"
              />

              <p class="p4">科技教育</p>
            </li>

            <li>
              <img
                src="	https://www.eol.cn/e_images/index/2022/index/service-tab2.png"
                alt=""
                class="img1"
              />
              <img
                src="	https://www.eol.cn/e_images/index/2022/index/hservice-tab2.png"
                alt=""
                class="img2"
              />

              <p class="p4">国际教育</p>
            </li>
          </a>
        </ul>
      </div>
      <div
        style="
          width: 100%;
          height: 360px;
          background-color: rgb(0, 78, 179);
          overflow: hidden;
        "
        class="service-view"
      >
        <div style="width: 1048px; height: 100%; margin: auto">
          <div class="service-view-title" style="margin-top: 60px">
            服务专栏
          </div>
          <ul class="" style="margin-top: 30px">
            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju15.png"
                />
                <div class="test">政策解读</div></a
              >
            </li>

            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju3.png"
                />
                <div class="test">智选校园</div></a
              >
            </li>

            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju2.png"
                />
                <div class="test">网上考研</div></a
              >
            </li>
            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju5.png"
                />
                <div class="test">技术测评</div></a
              >
            </li>

            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju6.png"
                />
                <div class="test">视频中心</div></a
              >
            </li>
            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju7.png"
                />
                <div class="test">生涯规划</div></a
              >
            </li>

            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju11.png"
                />
                <div class="test">科技成果</div></a
              >
            </li>
            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju8.png"
                />
                <div class="test">求职招聘</div></a
              >
            </li>
          </ul>
          <ul class="" style="margin-top: 30px">
            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju10.png"
                />
                <div class="test">人才评审</div></a
              >
            </li>
            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju11.png"
                />
                <div class="test">猎投服务</div></a
              >
            </li>

            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju12.png"
                />
                <div class="test">成人高考</div></a
              >
            </li>
            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju13.png"
                />
                <div class="test">继教问答</div></a
              >
            </li>
            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju4.png"
                />
                <div class="test">课程思政</div></a
              >
            </li>

            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju6.png"
                />
                <div class="test">在线教学</div></a
              >
            </li>

            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju14.png"
                />
                <div class="test">中职学校</div></a
              >
            </li>

            <li class="">
              <a href="http://edu.hainan.gov.cn/">
                <img
                  src="https://www.eol.cn/e_images/index/2022/index/gongju16.png"
                />
                <div class="test">交流活动</div></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div style="width: 100%; height: 200px; margin-top: 355px">
      <div class="shortcut">
        <div class="container">
          <div
            class="shortcut-wrap"
            style="padding-left: 150px; box-sizing: border-box"
          >
            <img
              src="https://s1.ax1x.com/2023/06/30/pC0Jk8J.png"
              alt="LOGO"
              class="c-image shortcut-logo"
              style="width: 261px; height: 80px"
            />

            <div
              class="shortcut-friendly-link"
              style="margin-left: 50px; width: 520px"
            >
              <div class="shortcut-friendly-link-title">快捷入口</div>
              <div class="shortcut-friendly-link-wrap" style="width: 520px">
                <div class="">
                  <a
                    href="http://edu.hainan.gov.cn/"
                    class="shortcut-friendly-link-item"
                    >海南省教育厅</a
                  >
                  <a
                    href="http://ea.hainan.gov.cn/"
                    class="shortcut-friendly-link-item"
                    >海南省考试局</a
                  >
                  <a
                    href="http://audit.hainan.gov.cn/"
                    class="shortcut-friendly-link-item"
                    >海南省审计厅</a
                  >
                  <a
                    href="http://mof.hainan.gov.cn/"
                    class="shortcut-friendly-link-item"
                    >海南省财政厅</a
                  >
                </div>
                <div class="">
                  <a
                    href="https://wssp.hainan.gov.cn/hnwt/home"
                    class="shortcut-friendly-link-item"
                    >海南政务服务网</a
                  >
                  <a
                    href="http://hrss.hainan.gov.cn/"
                    class="shortcut-friendly-link-item"
                    >海南省人力资源和社会保障厅</a
                  >
                  <a
                    href="https://hainanly.cn"
                    class="shortcut-friendly-link-item"
                    >海南旅游网</a
                  >
                  <a
                    href="http://hainan-rc.com"
                    class="shortcut-friendly-link-item"
                    >海南人才网</a
                  >
                </div>
              </div>
            </div>

            <div class="shortcut-qr"></div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%; height: 100px">
      <footer class="footer">
        <div class="friendlink">
          <a class="link" href="/html/cer.net/jj.htm">
            琼ICP备2020003380号-11 | 琼公网安备 46010802000753号</a
          >
          <!--<a class="link" href="//img.eol.cn/images/indexnew/www1024.jpg"
      >京网文[2014]2016-306号</a
    ><span class="line"></span
    >-->
        </div>

        <div class="right">
          <span class="text"
            >海南教育网 版权所有 中国网络集团监制
            技术支持：中网科技（海南）有限公司</span
          >
          <!-- <a class="link">北京中教双元科技集团有限公司</a> -->
          <!-- <span class="text">EOL Corporation</span> -->
        </div>

        <div class="contact">
          <!-- <span class="text">Mail to:</span> -->
          <a class="email" href="mailto:webmaster@eol.cn"
            >邮箱：kf@China-kj.cn 联系电话：0898-65669506</a
          >
        </div>
      </footer>
    </div>
  </div>
</template>
  
  <script>
// import "element-ui/lib/theme-chalk/base.css";
// import Sldentify from '@/pages/siddentify/index.vue'
import Swiper from "../../components/Swiper.vue";
// import swipers from '../../components/Swipers.vue'
export default {
  name: "ShoYe",
  data() {
    return {
      title: {},
      gg: {},
      lbttext: {},
      fll: {},
      yw: {},
      jyfb: {},
      tjjy: {},
      zcfg: {},
      jysj: {},
      zkgd: {},
      yxdt: {},
      zthd: {},
      jyws: {},

      inputvalue: "",
    };
  },
  components: {
    Swiper,
  },
  mounted() {
    this.gettitle();
    this.getgg();
    this.getlbttext();
    this.getfll();
    this.getjyyw();
    this.getjyfb();
    this.getjytjjy();
    this.getzcfg();
    this.getjysj();
    this.getjyzkgd();
    this.getjyyxdt();
    this.getjyzthd();
    this.getjyjyws();
  },

  methods: {
    async getjyjyws() {
      const res = await this.$store.dispatch("getjyjyws");
      this.jyws = { ...res };
    },
    async getjyzthd() {
      const res = await this.$store.dispatch("getjyzthd");
      this.zthd = { ...res };
    },
    async getjyyxdt() {
      const res = await this.$store.dispatch("getjyyxdt");
      this.yxdt = { ...res };
    },
    async getjyzkgd() {
      const res = await this.$store.dispatch("getjyzkgd");
      this.zkgd = { ...res };
    },
    async getjysj() {
      const res = await this.$store.dispatch("getjyjysj");
      this.jysj = { ...res };
    },
    async getzcfg() {
      const res = await this.$store.dispatch("getjyzcfg");
      this.zcfg = { ...res };
    },
    async getjytjjy() {
      const res = await this.$store.dispatch("getjytjjy");
      this.tjjy = { ...res };
    },
    async getjyfb() {
      const res = await this.$store.dispatch("getjyjyfb");
      this.jyfb = { ...res };
    },
    async getjyyw() {
      const res = await this.$store.dispatch("getjyjyyw");
      this.yw = { ...res };
    },

    async getfll() {
      const res = await this.$store.dispatch("getjyfll");
      this.fll = { ...res };
    },

    async getlbttext() {
      const res = await this.$store.dispatch("getjylbttext");
      this.lbttext = { ...res };
    },

    async getgg() {
      const res = await this.$store.dispatch("getjygg");
      this.gg = { ...res };
    },
    async gettitle() {
      const res = await this.$store.dispatch("getjytitle");
      this.title = { ...res };
    },
    inputHanlder() {
      window.open(`https://www.baidu.com/s?wd=${this.inputvalue}`);
    },
  },
};
</script>
  
  <style lang="css" scoped>
.s1 {
  color: black;
  margin-left: 20px;
}
.navul li {
  font-weight: bold;
}
.leftbox2ul li:hover {
  color: #004eb3;
}
.span1:hover {
  color: #004eb3;
}
.p2:hover {
  color: #004eb3;
}
.s1:hover {
  color: #004eb3;
}
.footer .friendlink .line {
  display: inline-block;
  width: 1px;
  height: 12px;
  margin: 0 6px;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.7);
  color: #fff;
}

.footer {
  padding: 20px 0 14px;
  height: 80px;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  background: #004eb3;
  color: #fff;
}
.footer .friendlink .link {
  color: #fff;
  vertical-align: middle;
}
.text {
  color: #fff;
}
.email,
.link {
  color: #fff;
}
.footer .right .link {
  margin: 0 6px;
}
.shortcut-logo {
  width: 122px;
  height: 56px;
  margin-top: 40px;
}
.shortcut-friendly-link-title::before,
.shortcut-qr-title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 76px;
  transform: translateY(-50%);
  width: 410px;
  height: 0.5px;
  background-color: #cfcfcf;
}
.shortcut-friendly-link-item {
  position: relative;
  line-height: 40px;
  font-size: 14px;
}
.shortcut-friendly-link-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 0.5px;
  height: 12px;
  z-index: 1;
  background-color: #cfcfcf;
}
.shortcut-friendly-link-item:not(:last-child) {
  padding-right: 15px;
  margin-right: 15px;
}
.shortcut {
  overflow: hidden;
  height: 200px;
  padding-top: 30px;
  background-color: #f6f6f6;
}
.container {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.shortcut-wrap {
  display: flex;
  justify-content: space-between;
}
.shortcut-friendly-link {
  width: 540px;
}
.shortcut-friendly-link-title,
.shortcut-qr-title {
  position: relative;
  margin-bottom: 14px;
  font-weight: bold;
}
.shortcut-friendly-link-wrap {
  font-size: 0;
}
.shortcut-qr {
  width: 264px;
}
.shortcut-friendly-link-title,
.shortcut-qr-title {
  position: relative;
  margin-bottom: 14px;
  font-weight: bold;
}
.shortcut-qr-wrap {
  font-size: 0;
}
.shortcut-qr-wrap .qr {
  display: inline-block;
  margin-left: 18px;
  text-align: center;
  font-size: 12px;
  color: #999;
}

.service-view ul {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
}
.service-view li a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  font-size: 14px;
  white-space: nowrap;
  color: #fff;
}
.service-view li:hover a {
  /* font-size: 12px; */
  font-weight: bold;
}
.service-view-title {
  color: #fff;
  position: relative;
  padding-left: 16px;
  margin-bottom: 26px;
  margin-top: 20px;
}
.service-view-title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 5px;
  height: 100%;
  background-color: #fff;
}
.p4 {
  margin-top: 10px;
  font-size: 18px;
  width: 72px;
  text-align: center;
}
.iconul {
  display: flex;
  margin-left: 60px;
  align-items: center;
}
.iconul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 20px;
}
.img2 {
  width: 60px;
  height: 60px;
  display: none;
}
.img1 {
  width: 60px;
  height: 60px;
  /* display: none; */
}
.iconul li:hover .img2 {
  display: block;
}
.iconul li:hover .img1 {
  display: none;
}
.iconul li:hover .p4 {
  color: rgb(0, 78, 179);
}
li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leftbox2ul li {
  font-size: 14px;
  margin-top: 20px;
}
.span1 {
  width: 245px;
  height: 48px;
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
}
.p3 {
  font-size: 18px;
  margin-left: 20px;
}
.p3:hover {
  color: rgb(0, 78, 179);
}
.righbox2ul {
}
.righbox2ul li {
  margin-top: 15px;
  font-size: 14px;
}
.righbox2ul li:hover {
  color: rgb(0, 78, 179);
}
.p2 {
  font-size: 16px;
}

.p1::before {
  content: "";
  position: absolute;
  left: 0px;
  top: calc(50%);
  transform: translateY(-50%);
  display: block;
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
}
.p1 {
  margin-left: 10px;
  font-size: 14px;
}
.p1:hover {
  color: rgb(0, 78, 179);
}
.ulimg li {
  height: 100px;
  width: 285px;
  /* float: left; */
}
.ulimg {
  display: flex;
  justify-content: space-between;
}
.lbtbox:hover {
  color: rgb(0, 78, 179);
}
.rightbox1 {
  width: 780px;
  height: 322px;
  /* background-color: aqua; */
  margin-left: 60px;
}
.leftbox1ul li:hover {
  color: rgb(0, 78, 179);
}
.leftbox1li {
  margin-top: 15px;
  font-size: 16px;
}
.leftbox1 {
  width: 406px;
  height: 100%;
  /* background-color: green; */
}
.zxnl {
  padding-left: 35px;
  box-sizing: border-box;
  display: flex;
  /* justify-content: space-between; */
  /* margin-right: 20px; */
  margin-top: 50px;
}
.zxnl li {
  margin-right: 20px;
  font-size: 16px;
}
.zxnl li:hover {
  color: rgb(0, 78, 179);
}
.title {
  font-size: 36px;
}
.title:hover {
  color: rgb(0, 78, 179);
}
.navultwo li:hover {
  color: rgb(0, 78, 179);
}
.navultwo li {
  position: relative;
  float: left;
  width: 80px;
  text-align: center;
  font-size: 14px;

  line-height: 40px;
  color: #333333;
  /* margin-left: 30px; */
}
.nav_icon {
  display: none;
}
.navul li:hover .nav_icon {
  display: block;
}
.navul li {
  position: relative;
  float: left;
  width: 80px;
  text-align: center;
  font-size: 20px;
  line-height: 60px;
  color: #fff;
  /* margin-left: 30px; */
}
li {
  list-style: none;
}
a {
  color: #333333;
  text-decoration: none;
}
.dful li {
  float: left;
  width: 46px;
  text-align: center;
  margin-left: 11px;
  height: 15px;
  font-size: 13px;
  margin-top: 21px;
}

.dfshow {
  display: none;
  position: absolute;
  top: 27px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 355px;
  height: 235px;
  z-index: 99;
}
.dfbox:hover .dfshow {
  display: block;
}
.dfbox {
  background-color: #fff;
  color: #333333;
}
.dfbox:hover {
  color: #fff;
  background-color: rgb(0, 78, 179);
  border: none;
}
/* .dfbox:hover .iconbox1{
  display: none;
}
.iconbox2{
  display: none;
}
.dfbox:hover .iconbox2{
  display:block;
} */
.top1 {
  height: 100%;
  position: relative;
  font-size: 12px;
  color: #333333;
}
.top1box {
  display: none;
  width: 88px;
  height: 230px;
  box-shadow: 0 0 10px #ccc;
  background-color: #fff;
  position: absolute;
  top: 46px;
  right: 0;
  z-index: 10;
}
.top1box li {
  list-style: none;
  text-align: center;
  margin-top: 15px;
}
.top1box li a {
  list-style: none;
  color: black;
  text-decoration: none;
}
.topone:hover .top1box {
  display: block;
}
.top2box {
  display: none;
  box-shadow: 0 0 10px #ccc;
  width: 88px;
  height: 260px;
  background-color: #fff;
  position: absolute;
  top: 46px;
  right: 0;
  z-index: 10;
}
.top2box li {
  list-style: none;
  text-align: center;
  margin-top: 15px;
}
.top2box li a {
  list-style: none;
  color: black;
  text-decoration: none;
}
.toptwo:hover .top2box {
  display: block;
}
</style>

  