import { getjyjyws, getjyzthd, getjyyxdt, getjyzkgd, getjyjysj, getjyzcfg, getjytjjy, getjyjyfb, getjyjyyw, getjyfll, getjylbttext, getjylbt, getjygg, getjytitle, yuyueset, setjzorder, setwjorder, getwlgslbt } from '@/api/zwkj/index'
const actions = {
    async getjyjyws(context, data) {

        console.log(context, data)
        const res = await getjyjyws()
        console.log(res)
        return res.message
    },
    async getjyzthd(context, data) {

        console.log(context, data)
        const res = await getjyzthd()
        console.log(res)
        return res.message
    },
    async getjyyxdt(context, data) {

        console.log(context, data)
        const res = await getjyyxdt()
        console.log(res)
        return res.message
    },
    async getjyzkgd(context, data) {

        console.log(context, data)
        const res = await getjyzkgd()
        console.log(res)
        return res.message
    },
    async getjyjysj(context, data) {

        console.log(context, data)
        const res = await getjyjysj()
        console.log(res)
        return res.message
    },
    async getjyzcfg(context, data) {

        console.log(context, data)
        const res = await getjyzcfg()
        console.log(res)
        return res.message
    },
    async getjytjjy(context, data) {

        console.log(context, data)
        const res = await getjytjjy()
        console.log(res)
        return res.message
    },
    async getjyjyfb(context, data) {
        console.log(context, data)
        const res = await getjyjyfb()
        console.log(res)
        return res.message
    },
    async getjyjyyw(context, data) {
        console.log(context, data)
        const res = await getjyjyyw()
        console.log(res)
        return res.message
    },
    async getjyfll(context, data) {
        console.log(context, data)
        const res = await getjyfll()
        console.log(res)
        return res.message
    },
    async getjylbttext(context, data) {
        console.log(context, data)
        const res = await getjylbttext()
        console.log(res)
        return res.message
    },
    async getjylbt(context, data) {
        console.log(context, data)
        const res = await getjylbt()
        console.log(res)
        return res.message
    },
    async getjygg(context, data) {
        console.log(context, data)
        const res = await getjygg()
        console.log(res)
        return res.message
    },
    async getjytitle(context, data) {
        console.log(context, data)
        const res = await getjytitle()
        console.log(res)
        return res.message
    },

    async getwlgslbt(context, data) {
        console.log(context, data)
        const res = await getwlgslbt()
        console.log(res)
        return res.message
    },






    async yuyueset(context, data) {
        const res = await yuyueset(data);
        console.log(res)
        return res.statu
    },
    async setjzorder(context, data) {
        console.log(data)
        const res = await setjzorder({...data });
        console.log(res)
            // console.log(res);
        return res
    },
    async setwjorder(context, data) {
        const res = await setwjorder({...data })
        return res
    }
};
const mutations = {
    GETSEARCHLIST(state, datas) {
        let { data, data1 } = datas
        state.searchlist = data;
        state.nums = data1

    }
};
const state = {
    searchlist: [],
    nums: 0
};
const getters = {};
export default {
    actions,
    mutations,
    state,
    getters
}